:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  /* font-family: 'Poppins', serif; */
}

body {
  line-height: 1.35 !important;
  /* font-family: 'Poppins', serif; */
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

main {
  margin: 2rem;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 14px;
}

/* Standardizes space around pages in the App Layout content area  */
.MuiBox-root > .scrollbar-container.ps > div {
  margin: 2rem;
}

/* Hide horizontal scroll bar in left nav container */
nav.VFSidebar .scrollbar-container {
  overflow-x: hidden !important;
}

/* 
  Applies spacing around most dialogs
  that previously did not have padding around the edges
*/
[role='dialog'] > form {
  padding-bottom: 1rem;
}

[role='dialog'] > form > header {
  padding-left: 1rem;
  padding-right: 1rem;
}

[role='dialog'] > form .MuiGrid-container {
  padding-left: 1rem;
  padding-right: 1rem;
}
